.hero__slider {
	display: flex;
	flex-direction: column;
	position: relative;
}

.slider__item {
	max-width: 100%;
	height: 635px;
	z-index: 1;
}

.slider__item-01 {
	background: linear-gradient(rgb(0, 13, 107, 0.5), rgb(0, 13, 107, 0.5)),
		url("../assets/all-images/possibleSlider/pexels-jonathan-borba-3316922.jpg");
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}
.slider__item-02 {
	background: linear-gradient(rgb(0, 13, 107, 0.5), rgb(0, 13, 107, 0.5)),
		url("../assets/all-images/possibleSlider/pexels-vecislavas-popa-1571460.jpg");
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

.slider__item-03 {
	background: linear-gradient(rgb(0, 13, 107, 0.5), rgb(0, 13, 107, 0.5)),
		url("../assets/all-images/possibleSlider/pexels-max-rahubovskiy-6899359.jpg");
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

.hero__slider {
	overflow: hidden;
}

.slider__content {
	padding-top: 150px;
	direction: rtl;
}

.slider__content h2 {
	font-size: 3.5rem;
	font-weight: 600;
}
.slider__content h4 {
	font-size: 2rem;
	font-weight: 400;
}
@media only screen and (max-width: 767px) {
	.slider__content h2 {
		font-size: 2.5rem;
		font-weight: 600;
	}
	.slider__content h4 {
		font-size: 1.2rem;
		font-weight: 300;
	}
}
