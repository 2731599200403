.blog__details {
	direction: rtl;
}
.ahmed h6 {
	direction: rtl;
}
.blog__details img {
	width: 100%;
	height: 100%;
	max-height: 50rem;
}

blockquote {
	line-height: 35px;
}
.recent__post h5 {
	font-size: 25px !important;
	font-weight: 200 !important;
	border-bottom: 1px solid #7c8a972e;
	padding-bottom: 0.5rem;
}
.recent__post:hover h5 {
	color: #f9a826;
}
.recent__blog-item img {
	max-height: 5rem;
}

.recent__blog-item h6 a {
	text-decoration: none;
	color: #000d6b;
	font-weight: 600;
	&:hover {
		color: #f9a826;
	}
}

@media only screen and (max-width: 768px) {
	.recent__post {
		margin-top: 0px;
	}

	.blog__details p {
		font-size: 0.8rem;
		line-height: 1.4rem;
	}
}
