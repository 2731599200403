.blog__item {
	border: 1px solid #7c8a972e;
	direction: rtl;
}
.blog__item img {
	max-height: 325px;
}

.read__more {
	color: #f9a826;
	font-weight: 600;
	text-decoration: none;
	font-size: 1rem;
}

.blog__title {
	text-decoration: none;
	color: #000d6b;
	font-size: 1.2rem;
	font-weight: 600;
	transition: 0.3s;
}

.blog__title:hover {
	color: #f9a826;
}
@media only screen and (max-width: 992px) {
	.blog__item img {
		max-height: 255px;
	}
}
@media only screen and (max-width: 768px) {
	.blog__item img {
		max-height: 200px;
	}

	.blog__time {
		padding-top: 5px !important;
	}

	.blog__time span {
		font-size: 0.7rem;
	}

	.blog__info p {
		font-size: 0.7rem;
		line-height: 1.5rem;
		margin-top: 10px;
	}

	.blog__title {
		font-size: 1.1rem;
	}

	.read__more {
		font-size: 0.7rem;
	}
}
