/* ======== google fonts ======== */
/* @import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;300;400;600;700;900&display=swap"); */
/* @import url("https://fonts.googleapis.com/css2?family=Cairo:wght@400;500;600;700;800&family=Noto+Sans+Arabic:wght@100;200;400;600;700;800&family=Open+Sans:wght@400;700&family=Rubik:wght@300;400;500;600;700;800&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@300;400;500;600;700;800&family=Noto+Sans+Arabic:wght@100;200;400;600;700;800&family=Open+Sans:wght@400;700&family=Rubik:wght@300;400;500;600;700;800&display=swap");

/* ======= base style ====== */
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	/* font-family: "Source Sans Pro", sans-serif; */
	font-family: "Cairo", sans-serif;
	font-family: "Noto Sans Arabic", sans-serif;
	font-family: "Open Sans", sans-serif;
	font-family: "Rubik", sans-serif;
}

/* ========= pre-define css ========= */
h1,
h2,
h3,
h4,
h5,
h6 {
	color: #000d6b;
	font-weight: 600;
	margin-bottom: 0;
}

h3,
h4 {
	font-size: 1.6rem;
}

h5,
h6 {
	font-size: 1.2rem;
}

a {
	text-decoration: none;
	color: unset;
}

a:hover {
	color: unset;
}

ul {
	margin: 0;
	list-style: none;
}

.section__title {
	color: #000d6b;
	font-weight: 400;
	font-size: 1.5rem;
}

.section__subtitle {
	color: #f9a826;
	font-weight: 500;
	font-size: 3rem;
}

.section__description {
	color: #7c8a97;
	font-size: 1rem;
	line-height: 30px;
}

section {
	padding: 50px 0px;
}

.btn {
	padding: 7px 15px;
	background: #000d6b;
	color: #fff;
	border: none;
	outline: none;
	border-radius: 5px;
	transition: 0.3s;
}

.btn:hover {
	color: #fff !important;
	background: #000c6bd0;
}

@media only screen and (max-width: 768px) {
	section {
		padding: 30px 0px;
	}
	.section__title {
		font-size: 1.1rem !important;
	}
	.section__subtitle {
		font-size: 2rem;
	}
	* {
		overflow-x: hidden;
	}
}
@media only screen and (max-width: 574px) {
	* {
		overflow-x: hidden;
	}
}
