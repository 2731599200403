/* Logo styling */
.header_logo {
	position: absolute;
	top: 10px;
	left: 10px;
	max-width: 200px;
	width: 100%;
}
.header_logo img {
	width: 45px;
}
.header_contact {
	display: none;
}
/* ================ main navbar styles =========== */

.main__navbar {
	padding: 20px 0px;
	background: #000d6b;
	direction: rtl;
	display: flex;
	justify-content: space-between;
}

.mobile__menu i {
	color: #fff;
	font-size: 1.3rem;
	cursor: pointer;
}

.mobile__menu {
	display: none;
}

.menu {
	display: flex;
	align-items: center;
	column-gap: 2.7rem;
}

.nav__item {
	color: #fff;
	text-decoration: none;
	transition: 0.3s;
}

.nav__item:hover {
	color: #f9a826;
}

.nav__active {
	color: #f9a826;
}

@media only screen and (max-width: 768px) {
	.header_logo {
		display: none;
	}
	.header_contact {
		display: block;
		position: absolute;
		top: 15px;
		left: 100px;
		font-size: 1.5rem;
		color: #f4f0f0;
	}
	.header_contact a {
		text-decoration: none;
		color: #f4f0f0;
	}
	.mobile__menu {
		display: block;
		color: #f4f0f0;
	}

	.navigation {
		background: rgb(0, 13, 107, 0.6);
		width: 100%;
		height: 100%;
		position: fixed;
		top: 0;
		right: 0;
		z-index: 9999;
		display: none;
	}

	.menu {
		width: 250px;
		height: 100%;
		background: #fff;
		display: flex;
		align-items: center;
		flex-direction: column;
		row-gap: 2rem;
		justify-content: center;
	}

	.menu a {
		color: #000d6b;
		font-weight: 600;
		font-size: 1.2rem;
	}

	.menu__active {
		display: block;
	}
}
@media only screen and (max-width: 600px) {
	.header_contact {
		top: 18px;
		left: 30px;
	}
}
