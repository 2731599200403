.social-links {
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 2;
}
.social-media-icon {
	width: 80px;
	height: 80px;
	text-align: center;
	text-decoration: none;
	color: #000;
	box-shadow: 0 0 20px 10px rgba(0, 0, 0, 0.05);
	margin: 0 15px;
	border-radius: 50%;
	position: relative;
	overflow: hidden;
	transition: transform 0.5s;
}
.social-media-icon i {
	font-size: 3rem;
	line-height: 5rem;
	position: relative;
	z-index: 10;
	transition: color 0.5s;
}
.social-links .social-media-icon::after {
	content: "";
	width: 100%;
	height: 100%;
	top: -85px;
	left: 0;
	background: linear-gradient(-45deg, #ed1c94, #ffec17);
	position: absolute;
	transition: 0.5s;
}
.social-links .social-media-icon:hover::after {
	top: 0;
}
.social-links .social-media-icon:hover i {
	color: #fff;
}
.social-links .social-media-icon:hover {
	transform: translateY(-10px);
}
@media only screen and (max-width: 767px) {
	.social-media-icon {
		margin: 0;
	}
	.social-media-icon i {
		font-size: 2rem;
		line-height: 5rem;
	}
	.social-links .social-media-icon::after {
		top: -85px;
	}
}
